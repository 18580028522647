import { SETTINGS_MANAGE, SETTINGS_VIEW } from 'constants/permissions'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { ReactLazyPreload } from 'utilities/PreloadLinks'

const SettingsDirectory = ReactLazyPreload(
  () => import(/* webpackChunkName: 'Settings' */ '../components/SettingsDirectory/index')
)

const OpeningHours = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsOpeningHours' */ '../components/SettingsDirectory/Site/OpeningHours'
  ).then((file) => {
    return { default: file.OpeningHours }
  })
)

const Zones = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'Zones' */ '../components/SettingsDirectory/Site/Zones').then(
    (file) => {
      return { default: file.Zones }
    }
  )
)

const PaymentTypes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsPaymentTypes' */ '../components/SettingsDirectory/Site/PaymentTypes'
  ).then((file) => {
    return { default: file.PaymentTypes }
  })
)

const RevenueCentres = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsRevenueCentres' */ '../components/SettingsDirectory/Organisation/RevenueCentres'
  ).then((file) => {
    return { default: file.RevenueCentres }
  })
)

const OfferingAvailabilityCalendar = ReactLazyPreload(
  () =>
    import(
      /* webpackChunkName: 'OfferingAvailabilityCalendar' */ '../screens/OfferingAvailabilityCalendar/OfferingAvailabilityCalendar'
    )
)

const Practitioners = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'Practitioners' */ '../components/SettingsDirectory/Resources/Practitioners'
  ).then((file) => {
    return { default: file.Practitioners }
  })
)

const Categories = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsCategories' */ '../components/SettingsDirectory/Site/Categories'
  ).then((file) => {
    return { default: file.Categories }
  })
)

const VoucherDelivery = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsVoucherDelivery' */ '../components/SettingsDirectory/Site/VoucherDelivery'
  ).then((file) => {
    return { default: file.VoucherDelivery }
  })
)

const Rooms = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'Rooms' */ '../components/SettingsDirectory/Resources/Rooms').then(
    (file) => {
      return { default: file.Rooms }
    }
  )
)

const BookableAreas = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsBookableAreas' */ '../components/SettingsDirectory/Resources/BookableAreas'
  ).then((file) => {
    return { default: file.BookableAreas }
  })
)

const Equipment = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'Equipment' */ '../components/SettingsDirectory/Resources/Equipment'
  ).then((file) => {
    return { default: file.Equipment }
  })
)

const BlockedTimePresets = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BlockedTimePresets' */ '../components/SettingsDirectory/Resources/BlockedTimePresets'
  ).then((file) => {
    return { default: file.BlockedTimePresets }
  })
)

const AppointmentTypes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AppointmentTypesSettings' */ '../components/SettingsDirectory/Offerings/AppointmentTypes'
  ).then((file) => ({
    default: file.AppointmentTypes,
  }))
)

const SessionTypes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SessionTypesSettings' */ '../components/SettingsDirectory/Offerings/SessionTypes'
  ).then((file) => ({
    default: file.SessionTypes,
  }))
)

const AreaBookingTypes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AreaBookingTypes' */ '../components/SettingsDirectory/Offerings/AreaBookingTypes'
  ).then((file) => {
    return { default: file.AreaBookingTypes }
  })
)

const RetailProducts = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RetailProducts' */ '../components/SettingsDirectory/Offerings/RetailProducts'
  ).then((file) => ({
    default: file.RetailProducts,
  }))
)

const TableReservationTypes = ReactLazyPreload(
  () =>
    import(
      /* webpackChunkName: 'TableReservationTypes' */ '../components/SettingsDirectory/Offerings/TableReservationTypes'
    )
)

const RoomReservationTypes = ReactLazyPreload(
  () =>
    import(
      /* webpackChunkName: 'RoomReservationTypes' */ '../components/SettingsDirectory/Offerings/RoomReservationTypes'
    )
)

const Packages = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PackagesSettings' */ '../components/SettingsDirectory/Offerings/Packages'
  ).then((file) => ({
    default: file.Packages,
  }))
)

const Courses = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'CoursesSettings' */ '../components/SettingsDirectory/Offerings/Courses'
  ).then((file) => ({
    default: file.Courses,
  }))
)

const VoucherTypes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'VoucherTypesSettings' */ '../components/SettingsDirectory/Offerings/VoucherTypes'
  ).then((file) => ({
    default: file.VoucherTypes,
  }))
)

const Membership = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsMembership' */ '../components/SettingsDirectory/Clients/Membership'
  ).then((file) => {
    return { default: file.Membership }
  })
)

const CustomerLabels = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsClientLabels' */ '../components/SettingsDirectory/Clients/ClientLabels'
  ).then((file) => {
    return { default: file.ClientLabels }
  })
)

const IntakeFormBuilder = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsIntakeFormBuilder' */ '../components/SettingsDirectory/Clients/IntakeFormBuilder'
  ).then((file) => {
    return { default: file.IntakeFormBuilder }
  })
)

const ShopSettings = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsShop' */ '../components/SettingsDirectory/Marketing/Shop'
  ).then((file) => {
    return { default: file.ShopSettings }
  })
)

const Legal = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsLegal' */ '../components/SettingsDirectory/Marketing/Legal'
  ).then((file) => {
    return { default: file.Legal }
  })
)

const MarketingPreferences = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsMarketingPreferences' */ '../components/SettingsDirectory/Marketing/MarketingPreferences'
  ).then((file) => {
    return { default: file.MarketingPreferences }
  })
)

const EmailBranding = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'EmailBrandingSettings' */ '../components/SettingsDirectory/Marketing/EmailBranding'
  ).then((file) => {
    return { default: file.EmailBranding }
  })
)

const Emails = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'SettingsEmails' */ '../components/SettingsDirectory/Emails').then(
    (file) => {
      return { default: file.EmailsSettings }
    }
  )
)

const SmsNotifications = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SmsNotifications' */ '../components/SettingsDirectory/Marketing/SmsNotifications'
  ).then((file) => {
    return { default: file.SmsNotifications }
  })
)

const DiscountTypesAndPromoCodes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'DiscountTypesAndPromoCodes' */ '../components/SettingsDirectory/Orders/DiscountTypesAndPromoCodes'
  ).then((file) => ({
    default: file.DiscountTypesAndPromoCodes,
  }))
)

const SalesChannels = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SalesChannels' */ '../components/SettingsDirectory/Orders/SalesChannels'
  ).then((file) => ({
    default: file.SalesChannels,
  }))
)

const OrderLabels = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OrderLabels' */ '../components/SettingsDirectory/Orders/OrderLabels'
  ).then((file) => ({
    default: file.OrderLabels,
  }))
)

const AppointmentEnquiries = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AppointmentEnquiries' */ '../components/SettingsDirectory/Site/AppointmentEnquiries'
  ).then((file) => ({
    default: file.AppointmentEnquiries,
  }))
)

const ServiceCharge = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ServiceCharge' */ '../components/SettingsDirectory/Orders/ServiceCharge'
  ).then((file) => ({
    default: file.ServiceCharge,
  }))
)

const InventoryCategories = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventoryCategories' */ '../components/SettingsDirectory/InventoryCategories'
  ).then((file) => ({
    default: file.InventoryCategories,
  }))
)

const InventoryBrands = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventoryBrands' */ '../components/SettingsDirectory/InventoryBrands'
  ).then((file) => ({
    default: file.InventoryBrands,
  }))
)

const InventorySuppliers = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventorySuppliers' */ '../components/SettingsDirectory/InventorySuppliers'
  ).then((file) => ({
    default: file.InventorySuppliers,
  }))
)

const Integrations = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'Integrations' */ '../components/SettingsDirectory/Site/Integrations'
  ).then((file) => {
    return { default: file.Integrations }
  })
)

const ExternalVouchers = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ExternalVouchers' */ '../components/SettingsDirectory/ExternalVouchers'
  ).then((file) => ({
    default: file.Vouchers,
  }))
)

const Developer = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'MyAccount' */ '../screens/MyAccount/Developer').then((file) => ({
    default: file.Developer,
  }))
)

const Details = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'MyAccount' */ '../screens/MyAccount/Details').then((file) => ({
    default: file.Details,
  }))
)

const NotificationSettings = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'MyAccount' */ '../screens/MyAccount/NotificationSettings').then(
    (file) => ({
      default: file.NotificationSettings,
    })
  )
)

const Security = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'MyAccount' */ '../screens/MyAccount/Security').then((file) => ({
    default: file.Security,
  }))
)

const Users = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'Users' */ '../components/SettingsDirectory/Site/Users').then(
    (file) => {
      return { default: file.Users }
    }
  )
)

const Billing = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SettingsBilling' */ '../components/SettingsDirectory/System/Billing'
  ).then((file) => {
    return { default: file.Billing }
  })
)

export const SettingsDirectoryRoute = () => {
  return [
    {
      path: '/settings',
      name: 'Settings',
      component: SettingsDirectory,
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
  ]
}

export const useRoutes = () => {
  const featureFlags = useActiveFeatureFlags()

  return [
    {
      path: '/settings/site/opening-hours',
      name: 'Opening hours',
      component: OpeningHours,
      category: 'site',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/site/zones',
      name: 'Zones',
      component: Zones,
      category: 'site',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/site/payment-types',
      name: 'Payment types',
      component: PaymentTypes,
      category: 'site',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/organisation/revenue-centres',
      name: 'Revenue centres',
      component: RevenueCentres,
      category: 'site',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/offering-availability',
      name: 'Unavailable dates',
      component: OfferingAvailabilityCalendar,
      category: 'site',
      exact: true,
      permissions: [SETTINGS_MANAGE],
    },
    {
      path: '/settings/site/categories',
      name: 'Categories',
      component: Categories,
      category: 'site',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/site/voucher-delivery',
      name: 'Voucher Delivery',
      component: VoucherDelivery,
      category: 'site',
      exact: true,
      permissions: [SETTINGS_VIEW],
      hide: !featureFlags.includes('voucher_delivery_method'),
    },
    {
      path: '/settings/site/appointment-enquiries',
      name: 'Appointment enquiries',
      component: AppointmentEnquiries,
      category: 'orders',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/resources/practitioners',
      name: 'Practitioners',
      component: Practitioners,
      category: 'resources',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/resources/rooms',
      name: 'Rooms',
      component: Rooms,
      category: 'resources',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/resources/bookable-areas',
      name: 'Bookable areas',
      component: BookableAreas,
      category: 'resources',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/resources/equipment',
      name: 'Equipment',
      component: Equipment,
      category: 'resources',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/resources/blocked-time-presets',
      name: 'Blocked times',
      component: BlockedTimePresets,
      category: 'resources',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/offerings/appointment-types',
      name: 'Appointment types',
      component: AppointmentTypes,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/offerings/session-types',
      name: 'Session types',
      component: SessionTypes,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/offerings/area-booking-types',
      name: 'Area booking types',
      component: AreaBookingTypes,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/offerings/retail-products',
      name: 'Retail products',
      component: RetailProducts,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/table-reservation-types',
      name: 'Table reservation types',
      component: TableReservationTypes,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/room-reservation-types',
      name: 'Room reservation types',
      component: RoomReservationTypes,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
      hide: !featureFlags.includes('overnights'),
    },
    {
      path: '/settings/offerings/package-types',
      name: 'Packages',
      component: Packages,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/offerings/courses',
      name: 'Courses',
      component: Courses,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/offerings/vouchers',
      name: 'Voucher types',
      component: VoucherTypes,
      category: 'offerings',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/clients/membership',
      name: 'Membership types',
      component: Membership,
      category: 'clients',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/clients/customer-labels',
      name: 'Client labels',
      component: CustomerLabels,
      category: 'clients',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/clients/intake-forms',
      name: 'Intake form sections',
      component: IntakeFormBuilder,
      category: 'clients',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/shop',
      name: 'Shop settings',
      component: ShopSettings,
      category: 'marketing',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/legal',
      name: 'Legal',
      component: Legal,
      category: 'marketing',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/marketing-preferences',
      name: 'Marketing preferences',
      component: MarketingPreferences,
      category: 'marketing',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/email-branding',
      name: 'Email branding',
      component: EmailBranding,
      category: 'marketing',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/orders/emails',
      name: 'Order emails',
      component: Emails,
      category: 'marketing',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/sms-notifications',
      name: 'SMS notifications',
      component: SmsNotifications,
      category: 'marketing',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/comms-locales',
      name: 'Communication locales',
      category: 'marketing',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/orders/discount-types-and-promo-codes',
      name: 'Discount types and promo codes',
      component: DiscountTypesAndPromoCodes,
      category: 'orders',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/orders/sales-channels',
      name: 'Sales channels',
      component: SalesChannels,
      category: 'orders',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/orders/order-labels',
      name: 'Order labels',
      component: OrderLabels,
      category: 'orders',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/orders/service-charge',
      name: 'Service charge',
      component: ServiceCharge,
      category: 'orders',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/inventory/categories',
      name: 'Stock categories',
      component: InventoryCategories,
      category: 'inventory',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/inventory/brands',
      name: 'Stock brands',
      component: InventoryBrands,
      category: 'inventory',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/inventory/suppliers',
      name: 'Suppliers',
      component: InventorySuppliers,
      category: 'inventory',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/integrations',
      name: 'Manage integrations',
      component: Integrations,
      category: 'integrations',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/external-vouchers',
      name: 'Vouchers',
      component: ExternalVouchers,
      category: 'integrations',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/my-account/developer',
      name: 'Developer',
      component: Developer,
      category: 'integrations',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/my-account/details',
      name: 'Details',
      component: Details,
      category: 'account',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/my-account/notifications',
      name: 'Notifications',
      component: NotificationSettings,
      category: 'account',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/my-account/security',
      name: 'Security',
      component: Security,
      category: 'account',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/users',
      name: 'Manage users',
      component: Users,
      category: 'system',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
    {
      path: '/settings/billing',
      name: 'Billing',
      component: Billing,
      category: 'system',
      exact: true,
      permissions: [SETTINGS_VIEW],
    },
  ]
}
