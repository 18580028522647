import { default as IntercomJs } from '@intercom/messenger-js-sdk'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useUser } from 'hooks/UseUser'

export const Intercom = () => {
  const { email, given_name: firstName, family_name: lastName, sub: userId } = useUser()
  const name = [firstName, lastName].join(' ').trim()
  const featureFlags = useActiveFeatureFlags()

  if (!featureFlags.includes('intercom')) return null

  if (!import.meta.env.VITE_APP_INTERCOM_APP_ID || !userId) return null

  IntercomJs({
    app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
    user_id: userId,
    name,
    email,
  })

  return null
}
