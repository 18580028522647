import VendorDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import enGB from 'date-fns/locale/en-GB'
import { XIcon } from '@heroicons/react/solid'
import { Input } from './Input'
import { useWindowWidth } from 'hooks/UseWindowWidth'
import { shift } from '@floating-ui/react-dom'

registerLocale('en-GB', enGB)
setDefaultLocale('en-GB')

type DateRangeValueFormat = [fromDate: Date, toDate: Date]

interface DateRangePickerProps {
  value: DateRangeValueFormat
  onChange: (date: DateRangeValueFormat) => void
  minDate?: Date
  clearButton?: boolean
  valueComponent?: any
  ref?: ForwardedRef<HTMLInputElement>
}

interface FormikProps {
  name: string
  value?: DateRangeValueFormat
  onChange: (date: DateRangeValueFormat) => void
}

export const DateRangePicker: React.FC<DateRangePickerProps> = forwardRef(
  ({ value, onChange, minDate = null, clearButton, valueComponent }, ref) => {
    const [selectedRange, setSelectedRange] = useState<DateRangeValueFormat>(value ?? [null, null])

    useEffect(() => {
      onChange(selectedRange)

      // eslint-disable-next-line
    }, [selectedRange])

    useEffect(() => {
      const inputTimestamps = [
        value[0] instanceof Date ? value[0].getTime() : null,
        value[1] instanceof Date ? value[1].getTime() : null,
      ]

      const selectedTimestamps = [
        selectedRange[0] instanceof Date ? selectedRange[0].getTime() : null,
        selectedRange[1] instanceof Date ? selectedRange[1].getTime() : null,
      ]

      const hasChanged =
        inputTimestamps[0] !== selectedTimestamps[0] || inputTimestamps[1] !== selectedTimestamps[1]

      if (hasChanged) {
        setSelectedRange(value)
      } else if (!value) {
        setSelectedRange([null, null])
      }
    }, [value])

    const windowWidth = useWindowWidth()

    const CustomInput: any = forwardRef(({ value, onClick }: any, ref) => (
      <div className="relative">
        <Input
          label="Date range"
          name="dateRange"
          hideLabel
          value={value}
          onChange={onChange}
          onFocus={onClick}
          className="sm:min-w-[190px]"
        />
        {clearButton && selectedRange.filter(Boolean).length > 0 && (
          <button
            type="button"
            onClick={() => {
              setSelectedRange([null, null])
              // Manually trigger the onChange too
              onChange([null, null])
            }}
            title="Clear"
            className="absolute right-0 top-0 p-1 rounded-full m-1 text-gray-400 hover:text-violet"
          >
            <XIcon className="w-5 h-5" />
          </button>
        )}
      </div>
    ))

    return (
      <VendorDatePicker
        minDate={minDate}
        onChange={setSelectedRange}
        startDate={selectedRange[0]}
        endDate={selectedRange[1]}
        locale="en-GB"
        dateFormat="dd/MM/yyyy"
        selectsRange
        popperPlacement="bottom"
        showPopperArrow={false}
        popperModifiers={[
          shift({
            padding: 10,
          }),
        ]}
        monthsShown={windowWidth > 768 ? 2 : 1}
        customInput={valueComponent ? valueComponent : <CustomInput />}
      />
    )
  }
)

export const FormikDateRangePicker = forwardRef<HTMLInputElement, FormikProps>(
  ({ name, value, onChange, ...props }, ref) => {
    return <DateRangePicker onChange={onChange} value={value} {...props} ref={ref} />
  }
)
