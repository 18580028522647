import { combineReducers } from 'redux'
import { AddCalendarEventOverlayReducer } from 'components/AddCalendarEventOverlay/reducer'
import { PractitionerScheduledAvailabilityReducer } from '@trybeapp/ducks.practitioner-scheduled-availability'
import { SessionTypesReducer } from '@trybeapp/ducks.sessions'
import BookingCalendarReducer from 'components/BookingCalendar/reducer'
import OrderList from 'screens/OrderList/reducer'
import PractitionerSummariesReducer from '@trybeapp/ducks.practitioner-summaries'
import {
  PackagesReducer as PackageTypesReducer,
  PackageItemChoicesReducer,
  PackageItemChoiceOptionsReducer,
} from '@trybeapp/ducks.packages'
import {
  BookingOrderItemsReducer,
  BookingSummariesReducer,
  OrdersReducer,
  PackageItemsReducer,
  PackageOrderItemsReducer,
  PurchaseOrderItemsReducer,
} from '@trybeapp/ducks.orders'
import { GuestsReducer } from '@trybeapp/ducks.guests'

export default combineReducers({
  OrderList,

  components: combineReducers({
    AddCalendarEventOverlayReducer,
    BookingCalendarReducer,
  }),

  resources: combineReducers({
    BookingOrderItemsReducer,
    BookingSummariesReducer,
    GuestsReducer,
    OrdersReducer,
    PackageItemChoiceOptionsReducer,
    PackageItemChoicesReducer,
    PackageItemsReducer,
    PackageOrderItemsReducer,
    PackageTypesReducer,
    PractitionerScheduledAvailabilityReducer,
    PractitionerSummariesReducer,
    PurchaseOrderItemsReducer,
    SessionTypesReducer,
  }),
})
